<template>
  <div class="franchise-apply">
    <div class="c-title">网点资质申请</div>
    <div style="color:red;margin-bottom:20px">
      {{ title }}
    </div>
    <el-form :model="franchiseFormData" label-width="150px" size="medium" :rules="franchiseFormRules"
      ref="franchiseFormRef" :disabled="isDisabledForm">
      <!-- <el-form-item label="公司简称：" prop="company_abbreviation">
        <el-input v-model="franchiseFormData.company_abbreviation"></el-input>
      </el-form-item> -->
      <el-form-item label="公司全称：" prop="company_full">
        <el-input v-model="franchiseFormData.company_full" placeholder="请输入公司名称（与营业执照保持一致）"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="company_phone">
        <el-input v-model="franchiseFormData.company_phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="营业执照：" prop="license">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/franchise/getUpload"
          :before-upload="beforeUpload" :on-success="licenseUpload" name="upfile">
          <el-image v-if="franchiseFormData.license" :src="franchiseFormData.license" class="cert" fit="scale-down" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="法人身份证复印件：">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/franchise/getUpload"
          :before-upload="beforeUpload" :on-success="photocopyUpload" name="upfile">
          <el-image v-if="franchiseFormData.photocopy" :src="franchiseFormData.photocopy" class="cert"
            fit="scale-down" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item> -->
      <!-- <el-form-item label="租赁合同/房产证：">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/franchise/getUpload"
          :before-upload="beforeUpload" :on-success="agreementUpload" name="upfile">
          <el-image v-if="franchiseFormData.agreement" :src="franchiseFormData.agreement" class="cert"
            fit="scale-down" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item> -->
      <el-form-item label="店铺照片：" prop="shopimg">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/franchise/getUpload"
          :before-upload="beforeUpload" :on-success="shopimgUpload" name="upfile">
          <el-image v-if="franchiseFormData.shopimg" :src="franchiseFormData.shopimg" class="cert" fit="scale-down" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="其他项：">
        <el-input type="textarea" :rows="4" placeholder="请填写备注" v-model="franchiseFormData.other" maxlength="50"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" size="medium" @click="sumbitFranchiseInfo()">提交审核</el-button>
        <el-form style="float:left;margin-right: 20px;">
          <el-form-item>
            <el-button type="info" size="medium" v-if="isEditBtn" @click="handleEdit()">修改</el-button>
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import {
    getFranchiseInfoApi,
    sumbitFranchiseInfoApi
  } from '@/api/franchise'
  export default {
    name: 'franchiseApply',
    data() {
      var phone = (rule, value, callback) => {
        let mobile = /^1[3|4|5|7|8][0-9]\d{8}$/; //手机
        let tel = /^(\d{3,4}-)?\d{7,8}$/; //座机
        if (!mobile.test(this.franchiseFormData.company_phone) && !tel.test(this.franchiseFormData.company_phone)) {
          callback(new Error('请输入正确合规的手机号码或座机号码'))
        } else {
          callback()
        }
      }
      return {
        franchiseFormData: {
          company_abbreviation: '',
          company_full: '',
          license: '',
          shopimg: '',
          other: '',
          status: '',
          company_phone: ''
        },
        isDisabledForm: false,
        title: '',
        isEditBtn: true,
        franchiseFormRules: {
          company_abbreviation: [{
            required: true,
            message: '请输入公司简称',
            trigger: 'blur'
          }],
          company_full: [{
            required: true,
            message: '请输入公司全称',
            trigger: 'blur'
          }],
          license: [{
            required: true,
            message: '请上传营业执照',
            trigger: 'change'
          }],
          //   photocopy: [{
          //     required: true,
          //     message: '请上传法人身份证复印件',
          //     trigger: 'change'
          //   }],
          //   agreement: [{
          //     required: true,
          //     message: '请上传租赁合同/房产证',
          //     trigger: 'change'
          //   }],
          shopimg: [{
            required: true,
            message: '请上传店铺照片',
            trigger: 'change'
          }],
          company_phone: [{
            required: true,
            validator: phone,
            trigger: 'blur'
          }]
        }
      }
    },
    created() {
      this.getFranchiseInfo()
    },
    methods: {
      async getFranchiseInfo() {
        const {
          data: res
        } = await getFranchiseInfoApi()
        if (Array.prototype.isPrototypeOf(res.result)) {
          this.franchiseFormData = {}
          this.isEditBtn = false
        } else {
          this.franchiseFormData = res.result
          if (this.franchiseFormData.status == 0) {
            this.title = '资料审核中...'
            this.isDisabledForm = true
            this.isEditBtn = false
          } else if (this.franchiseFormData.status == 1) {
            this.title = '资料审核通过...'
            this.isDisabledForm = true
          } else {
            this.title = '资料审核未通过,需修改后重新提交。'
            this.isDisabledForm = false
            this.isEditBtn = false
          }
        }
      },
      //  保存图片url
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' ? true : file.type === 'image/png' ? true : false;
        const isLt2M = file.size / 1024 / 1024 <= 2;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG或PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!');
        }
        return isJPG && isLt2M;
      },
      //调用this.$forceUpdate()方法, 直接调用vue的强制刷新机制
      licenseUpload(res) {
        this.franchiseFormData.license = res.result.fileName[0]
        this.$forceUpdate()
      },
      //   photocopyUpload(res) {
      //     this.franchiseFormData.photocopy = res.result.fileName[0]
      //     this.$forceUpdate()
      //   },
      //   agreementUpload(res) {
      //     this.franchiseFormData.agreement = res.result.fileName[0]
      //     this.$forceUpdate()
      //   },
      shopimgUpload(res) {
        this.franchiseFormData.shopimg = res.result.fileName[0]
        this.$forceUpdate()
      },
      //表单数据提交
      sumbitFranchiseInfo() {
        this.$refs.franchiseFormRef.validate(async (valid) => {
          if (!valid) return;
          const {
            data: res
          } = await sumbitFranchiseInfoApi(this.franchiseFormData)
          if (res.status != 10000) {
            return this.$message.error(res.message)
          }
          this.$message.success('提交成功')
          this.getFranchiseInfo()
        })
      },
      handleEdit() {
        this.isDisabledForm = false
      }
    },
  }

</script>

<style lang="less" scoped>
  .c-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }

  .cert-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 120px;

    .cert-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      min-height: 120px;
      line-height: 120px;
      text-align: center;
    }

    .cert {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }

  .cert-uploader:hover {
    border-color: #409eff;
  }

  .el-input {
    width: 50%;
  }

  /deep/ .el-textarea__inner {
    width: 70%;
  }

  /deep/ .el-input__count {
    bottom: 8%;
    right: 32%;
  }

  .el-textarea {
    width: 50%;
  }

</style>
